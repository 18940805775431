<template>
  <section>
    NotFound content
  </section>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>
